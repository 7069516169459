import React, { useState, useEffect } from "react";
import qore from "../../context/qoreContext";
import {
  Box,
  Stack,
  InputGroup,
  RadioGroup,
  Wrap,
  WrapItem,
  Progress,
  Center,
  Text,
  Button,
  Heading,
  Container,
  useMediaQuery,
  useBoolean,
  HStack,
  useRadio,
  useRadioGroup,
  Input,
  Image
} from "@chakra-ui/react";
import styled from "styled-components";
import PollBg from "../../images/polling_bg.png";

const Background = styled.div`
  background-image: url(${PollBg});
  background-size: 100% 100%;
  min-height: 80vh;
`;

const BeTheText = styled(Text)`
  font-weight: bold;
  font-size: 32pt;
  padding-top: 4pt;
  color: #991c21;

  @media screen and (max-width: 768px) {
    font-size: 28pt;
  }
`;

const QuestionsText = styled(Text)`
  font-family: "AvenirBold";
  font-size: 28pt;
  padding-top: 4pt;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 24pt;
  }
`;

const BodyText = ({ children }) => {
  return (
    <Text fontWeight={"800"} fontSize={"18pt"} color="#991C21" textAlign="center">
      {children}
    </Text>
  );
};

const CustomBox = ({ children }) => {
  return (
    <Center
      borderColor="#000000"
      borderWidth="1.5px"
      width="2em"
      height="2em"
      borderRadius="50%"
      pt={1}>
      <strong>{children}</strong>
    </Center>
  );
};

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1.5px"
        borderRadius="50px"
        borderColor="#D3D3D3"
        marginBottom="0.75em"
        _checked={{
          bg: "black",
          color: "white"
        }}
        _focus={{
          boxShadow: "outline"
        }}
        pl={3}
        pr={12}
        py={3}>
        {props.children}
      </Box>
    </Box>
  );
}

const PollComponent = () => {
  const [started, setStarted] = useBoolean(false);
  const [finished, setFinished] = useBoolean(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(25);
  const [indeks, setIndeks] = useState(0);
  const [totalField, setTotalField] = useState(0);
  const [isMobile] = useMediaQuery("(max-width: 820px)");
  const [formDataResult, setFormDataResult] = useState([]);
  const [message, setMessage] = useState("");
  const { insertRow } = qore.views.allSkincarePollResponses.useInsertRow();
  const { data: questionsComponent } = qore.views.allSkincarePoll.useListRow({
    "$by.questionIndex": "asc"
  });

  useEffect(() => {
    async function fetchData() {
      if (questionsComponent.length) {
        let FieldsbyFormsResult2 = [];
        var a = null;

        for (let sortingIndex = 0; sortingIndex < questionsComponent.length; sortingIndex++) {
          a = questionsComponent[sortingIndex].questionSlug.toString();
          FieldsbyFormsResult2.push({ [a]: null });
        }

        setFormDataResult(FieldsbyFormsResult2);
        setTotalField(questionsComponent.length + 1);
      }
    }
    fetchData();
  }, [questionsComponent]);

  const handleProgress = () => {
    let progressValue = progress + (1 / totalField) * 100;
    setProgress(progressValue);
  };

  const handleStart = () => {
    setStarted.on();
    setFinished.off();
    setProgress((2 / totalField) * 100);
  };

  const handleReset = () => {
    setStarted.off();
    setFinished.off();
    setProgress((1 / totalField) * 100);
    setIndeks(0);
  };

  const handleNext = () => {
    if (Object.values(formDataResult[indeks]).toString() !== "") {
      if (indeks < questionsComponent.length) {
        let input = indeks + 1;
        setIndeks(input);
        handleProgress();
      } else {
        handleProgress();
        setStarted.off();
        handleFinish();
      }
    } else {
      setMessage("Silakan jawab pertanyaan ini");
      setTimeout(function () {
        setMessage(null);
      }, 1500);
    }
  };

  const handleFinish = () => {
    setProgress((totalField / totalField) * 100);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    var Results = {};
    let isValid = false;
    let FieldName = "";

    for (let i = 0; i < formDataResult.length; i++) {
      FieldName = Object.keys(formDataResult[indeks]).toString();
      switch (FieldName === questionsComponent[indeks].questionSlug) {
        case true:
          if (Object.values(formDataResult[indeks]).toString() !== "") {
            Object.assign(Results, {
              [FieldName]: Object.values(formDataResult[indeks]).toString()
            });
            isValid = true;
          } else {
            setIsLoading(false);
            setMessage("Silakan jawab pertanyaan ini");
            isValid = false;
            setTimeout(function () {
              setMessage(null);
            }, 2000);
          }
          break;
        case false:
          break;
        default:
          setIsLoading(false);
          setMessage("Server error, please try again");
          setTimeout(function () {
            setMessage(null);
          }, 2000);
      }
    }

    if (isValid) {
      try {
        const pollInputsInserted = await insertRow(Results);
        if (!pollInputsInserted) throw false;
        setIsLoading(false);
        setFinished.on();
        setMessage("Success! Thank you for your answers");
        setTimeout(function () {
          setMessage(null);
        }, 1000);
        setTimeout(handleReset, 1500);
      } catch (error) {
        setIsLoading(false);
        setMessage("Server error, please try again");
        setTimeout(function () {
          setMessage(null);
        }, 2000);
      }
    }
  };

  const handleClick = e => {
    var tempFormDataResult = formDataResult;
    if (e.target.value) {
      tempFormDataResult[indeks][e.target.name] = e.target.value;
      setFormDataResult(tempFormDataResult);
    }
    setTimeout(function () {
      handleNext();
    }, 500);
  };

  const handleFinalClick = e => {
    var tempFormDataResult = formDataResult;
    if (e.target.value) {
      tempFormDataResult[indeks][e.target.name] = e.target.value;
      setFormDataResult(tempFormDataResult);
    }
  };

  const handleChange = e => {
    var tempFormDataResult = formDataResult;
    tempFormDataResult[indeks][e.target.name] = e.target.value;
    setFormDataResult(tempFormDataResult);
  };

  const StartComponent = () => {
    return (
      <Box>
        <Container
          pt="130px"
          pb={{ base: "120px", md: "64px" }}
          maxW={{ base: "98vw", md: "92vw", lg: "72vw", xl: "60vw" }}>
          <Box>
            <Center>
              <BeTheText>BE THE</BeTheText>
            </Center>
            <Center>
              <Heading
                style={{
                  fontFamily: "AvenirCondensedBold",
                  fontWeight: "bolder",
                  fontSize: isMobile ? "48pt" : "72pt",
                  marginBottom: "0.125em",
                  color: "#fe4b72",
                  WebkitTextStroke: isMobile ? "1.5px white" : "2px white",
                  textShadow: "0px 0px 14px #ff0034"
                }}>
                TRENDSETTER
              </Heading>
            </Center>
            <Center mb={6}>
              <Box maxWidth={isMobile ? "100%" : "45%"}>
                <BodyText>
                  Isi polling untuk terus melangkah maju dengan Skin Care OMG Oh My Glow
                </BodyText>
              </Box>
            </Center>
            <Center>
              <Button
                onClick={() => handleStart()}
                colorScheme="blackAlpha"
                bg="black"
                _focus={{ boxShadow: "none" }}
                _hover={{
                  bg: "white",
                  color: "black"
                }}
                style={{
                  padding: "1.25em",
                  fontSize: isMobile ? "14pt" : "18pt",
                  background: "#000000",
                  color: "#ffffff",
                  borderRadius: "30px"
                }}>
                Mulai Polling
              </Button>
            </Center>
          </Box>
        </Container>
      </Box>
    );
  };

  const FinishedComponent = () => {
    return (
      <Box>
        <Container
          pt="130px"
          pb={{ base: "120px", md: "64px" }}
          maxW={{ base: "98vw", md: "92vw", lg: "72vw", xl: "60vw" }}>
          <Box>
            <Center>
              <BeTheText>BE THE</BeTheText>
            </Center>
            <Center>
              <Heading
                style={{
                  fontFamily: "AvenirCondensedBold",
                  fontWeight: "bolder",
                  fontSize: isMobile ? "48pt" : "72pt",
                  marginBottom: "0.125em",
                  color: "#fe4b72",
                  WebkitTextStroke: isMobile ? "1.5px white" : "2px white",
                  textShadow: "0px 0px 14px #ff0034"
                }}>
                TRENDSETTER
              </Heading>
            </Center>
            <Center mb={6}>
              <Box maxWidth={isMobile ? "100%" : "45%"}>
                <BodyText>Terima kasih telah mengisi survey kami!</BodyText>
              </Box>
            </Center>
            <Center>
              <Button
                onClick={() => {
                  history.push("/");
                }}
                colorScheme="blackAlpha"
                bg="black"
                _focus={{ boxShadow: "none" }}
                _hover={{
                  bg: "white",
                  color: "black"
                }}
                style={{
                  padding: "1.25em",
                  fontSize: isMobile ? "14pt" : "18pt",
                  background: "#000000",
                  color: "#ffffff",
                  borderRadius: "30px"
                }}>
                Kembali
              </Button>
            </Center>
          </Box>
        </Container>
      </Box>
    );
  };

  const QuestionComponent = ({ indeks }) => {
    const abjad = ["A", "B", "C"];

    const { getRootProps, getRadioProps } = useRadioGroup({
      id: questionsComponent[indeks]?.questionSlug,
      name: questionsComponent[indeks]?.questionSlug,
      defaultValue: null
    });
    const group = getRootProps();

    return (
      <Box>
        <Container
          pt="5em"
          pb={{ base: "5em", md: "2.5em" }}
          maxW={{ base: "98vw", md: "92vw", lg: "72vw", xl: "60vw" }}>
          <Center pb={"2em"}>
            <QuestionsText>{questionsComponent[indeks]?.questionTitle}</QuestionsText>
          </Center>
          <Box>
            <Center>
              <Wrap spacing="3em" direction="column">
                <WrapItem>
                  <Stack
                    width={{ base: "300px", md: "350px" }}
                    mt="2em"
                    paddingBottom="1.25em"
                    borderRadius="20px"
                    background="#FFFFFF">
                    {questionsComponent[indeks]?.type === "multipleChoice" ? (
                      <Box borderRadius={"20px 20px 0 0"} background={"#000000"} mb={3} py={"1em"}>
                        <Center>
                          <Text color={"#FFF"}>{questionsComponent[indeks]?.subTitle}</Text>
                        </Center>
                      </Box>
                    ) : (
                      <Center mb="2.25em">
                        <Box
                          position="absolute"
                          borderRadius={"50%"}
                          width="4em"
                          background={"#000000"}>
                          <Image src={questionsComponent[indeks]?.questionImg} />
                        </Box>
                      </Center>
                    )}
                    {questionsComponent[indeks]?.type === "multipleChoice" ? (
                      <RadioGroup
                        {...group}
                        onClick={
                          questionsComponent[indeks]?.questionIndex === questionsComponent.length
                            ? handleFinalClick
                            : handleClick
                        }>
                        {questionsComponent[indeks]?.choices.choice.map((value, index) => {
                          return (
                            <>
                              <Box px={"1.5em"} pt={"0.5em"}>
                                <RadioCard
                                  key={value.title}
                                  onClick={
                                    questionsComponent[indeks]?.questionIndex ===
                                    questionsComponent.length
                                      ? handleFinalClick
                                      : handleClick
                                  }
                                  {...getRadioProps({ value: value.title })}
                                  defaultValue={null}>
                                  <HStack textAlign={"center"}>
                                    <CustomBox>{abjad[index]}</CustomBox>
                                    <Text>{value.title}</Text>
                                  </HStack>
                                </RadioCard>
                              </Box>
                            </>
                          );
                        })}
                      </RadioGroup>
                    ) : (
                      <>
                        <Text fontSize="16pt" mt={3} color={"#000"} textAlign="center">
                          {questionsComponent[indeks]?.subTitle}
                        </Text>
                        <Box px={"1.5em"} pt={"0.5em"}>
                          <InputGroup size="md">
                            <Input
                              name={questionsComponent[indeks]?.questionSlug}
                              pr="4.5rem"
                              type="tel"
                              onChange={event => handleChange(event)}
                              color="black"
                              maxLength="12"
                              variant="filled"
                            />
                          </InputGroup>
                        </Box>
                        {questionsComponent[indeks]?.questionIndex !== questionsComponent.length ? (
                          <>
                            <Center mt={6}>
                              <Button
                                mt={{ base: 4, lg: 4 }}
                                mb="-1em"
                                width={{ base: "300px", md: "350px" }}
                                borderRadius="15px"
                                size="lg"
                                onClick={handleNext}
                                position="static"
                                bg="black"
                                color="white"
                                border="1px solid black"
                                _focus={{ boxShadow: "none" }}
                                _hover={{
                                  bg: "black",
                                  color: "white"
                                }}
                                colorScheme="blackAlpha">
                                Next Question
                              </Button>
                            </Center>
                          </>
                        ) : null}
                      </>
                    )}
                  </Stack>
                </WrapItem>
              </Wrap>
            </Center>
            {questionsComponent[indeks]?.questionIndex === questionsComponent.length ? (
              <>
                {isLoading ? (
                  <>
                    <Center mt={6}>
                      <Button
                        py={{ base: 2, md: 4 }}
                        mb={{ base: 4, lg: 0 }}
                        width={{ base: "300px", md: "350px" }}
                        borderRadius="15px"
                        size="lg"
                        position="static"
                        bg="black"
                        color="white"
                        border="1px solid black"
                        colorScheme="blackAlpha"
                        isLoading
                        loadingText="Submitting">
                        Submit
                      </Button>
                    </Center>
                  </>
                ) : (
                  <>
                    {!finished ? (
                      <>
                        <Center mt={6}>
                          <Button
                            py={{ base: 2, md: 4 }}
                            mb={{ base: 4, lg: 0 }}
                            width={{ base: "300px", md: "350px" }}
                            borderRadius="15px"
                            size="lg"
                            onClick={handleSubmit}
                            position="static"
                            bg="black"
                            color="white"
                            border="1px solid black"
                            _focus={{ boxShadow: "none" }}
                            _hover={{
                              bg: "black",
                              color: "white"
                            }}
                            colorScheme="blackAlpha">
                            Submit
                          </Button>
                        </Center>
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : null}
            <Center mt={6}>
              <Text mb={8} fontSize="lg">
                {message}
              </Text>
            </Center>
          </Box>
        </Container>
      </Box>
    );
  };

  return (
    <Background>
      <Center mb={6} pt="20px">
        <Progress
          w={{ base: "90%", md: "50%", lg: "30%" }}
          value={progress}
          size="xs"
          colorScheme="gray"
        />
      </Center>
      <Box>
        {!started ? (
          <>{!finished ? <StartComponent /> : <FinishedComponent />}</>
        ) : (
          <>
            <QuestionComponent start="auto" indeks={indeks} />
          </>
        )}
      </Box>
    </Background>
  );
};

export default PollComponent;
