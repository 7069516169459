import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PollComponent from "../../components/polling/PollComponent.js";

const index = () => {
  return (
    <Layout>
      <Seo title="OMG Polling Page" />
      <PollComponent />
    </Layout>
  );
};

export default index;
